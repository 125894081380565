import { JSONContent } from "novel";

interface NovelElementsDb {
    type: string;
    content: (JSONContent & { id: string })[];
}

export const blankContent: JSONContent = {
    type: "doc",
    content: [
        {
            type: "paragraph",
            content: [],
        },
    ],
};

export const defaultEditorContent: NovelElementsDb = {
    type: "doc",
    content: [
        {
            id: "1",
            type: "heading",
            attrs: { level: 2 },
            content: [{ type: "text", text: "Introducing Novel" }],
        },
        {
            id: "2",
            type: "paragraph",
            content: [
                {
                    type: "text",
                    marks: [
                        {
                            type: "link",
                            attrs: {
                                href: "https://github.com/steven-tey/novel",
                                target: "_blank",
                            },
                        },
                    ],
                    text: "Novel",
                },
                {
                    type: "text",
                    text: " is a Notion-style WYSIWYG editor with AI-powered autocompletion. Built with ",
                },
                {
                    type: "text",
                    marks: [
                        {
                            type: "link",
                            attrs: {
                                href: "https://tiptap.dev/",
                                target: "_blank",
                            },
                        },
                    ],
                    text: "Tiptap",
                },
                { type: "text", text: " + " },
                {
                    type: "text",
                    marks: [
                        {
                            type: "link",
                            attrs: {
                                href: "https://sdk.vercel.ai/docs",
                                target: "_blank",
                            },
                        },
                    ],
                    text: "Vercel AI SDK",
                },
                { type: "text", text: "." },
            ],
        },
        {
            id: "3",
            type: "heading",
            attrs: { level: 3 },
            content: [{ type: "text", text: "Installation" }],
        },
        {
            id: "4",
            type: "codeBlock",
            attrs: { language: null },
            content: [{ type: "text", text: "npm i novel" }],
        },
        {
            id: "5",
            type: "heading",
            attrs: { level: 3 },
            content: [{ type: "text", text: "Usage" }],
        },
        {
            id: "6",
            type: "codeBlock",
            attrs: { language: null },
            content: [
                {
                    type: "text",
                    text: 'import { Editor } from "novel";\n\nexport default function App() {\n  return (\n     <Editor />\n  )\n}',
                },
            ],
        },
        {
            id: "7",
            type: "heading",
            attrs: { level: 3 },
            content: [{ type: "text", text: "Features" }],
        },
        {
            id: "8",
            type: "orderedList",
            attrs: { tight: true, start: 1 },
            content: [
                {
                    type: "listItem",
                    content: [
                        {
                            type: "paragraph",
                            content: [
                                {
                                    type: "text",
                                    text: "Slash menu & bubble menu",
                                },
                            ],
                        },
                    ],
                },
                {
                    type: "listItem",
                    content: [
                        {
                            type: "paragraph",
                            content: [
                                {
                                    type: "text",
                                    text: "AI autocomplete (type ",
                                },
                                {
                                    type: "text",
                                    marks: [{ type: "code" }],
                                    text: "++",
                                },
                                {
                                    type: "text",
                                    text: " to activate, or select from slash menu)",
                                },
                            ],
                        },
                    ],
                },
                {
                    type: "listItem",
                    content: [
                        {
                            type: "paragraph",
                            content: [
                                {
                                    type: "text",
                                    text: "Image uploads (drag & drop / copy & paste, or select from slash menu) ",
                                },
                            ],
                        },
                    ],
                },
            ],
        },
        {
            id: "9",
            type: "image",
            attrs: {
                src: "https://public.blob.vercel-storage.com/pJrjXbdONOnAeZAZ/banner-2wQk82qTwyVgvlhTW21GIkWgqPGD2C.png",
                alt: "banner.png",
                title: "banner.png",
                width: null,
                height: null,
            },
        },
        { type: "horizontalRule", id: "10" },
        {
            id: "11",
            type: "heading",
            attrs: { level: 3 },
            content: [{ type: "text", text: "Learn more" }],
        },
        {
            id: "12",
            type: "taskList",
            content: [
                {
                    type: "taskItem",
                    attrs: { checked: false },
                    content: [
                        {
                            type: "paragraph",
                            content: [
                                { type: "text", text: "Star us on " },
                                {
                                    type: "text",
                                    marks: [
                                        {
                                            type: "link",
                                            attrs: {
                                                href: "https://github.com/steven-tey/novel",
                                                target: "_blank",
                                            },
                                        },
                                    ],
                                    text: "GitHub",
                                },
                            ],
                        },
                    ],
                },
                {
                    type: "taskItem",
                    attrs: { checked: false },
                    content: [
                        {
                            type: "paragraph",
                            content: [
                                { type: "text", text: "Install the " },
                                {
                                    type: "text",
                                    marks: [
                                        {
                                            type: "link",
                                            attrs: {
                                                href: "https://www.npmjs.com/package/novel",
                                                target: "_blank",
                                            },
                                        },
                                    ],
                                    text: "NPM package",
                                },
                            ],
                        },
                    ],
                },
                {
                    type: "taskItem",
                    attrs: { checked: false },
                    content: [
                        {
                            type: "paragraph",
                            content: [
                                {
                                    type: "text",
                                    marks: [
                                        {
                                            type: "link",
                                            attrs: {
                                                href: "https://vercel.com/templates/next.js/novel",
                                                target: "_blank",
                                            },
                                        },
                                    ],
                                    text: "Deploy your own",
                                },
                                { type: "text", text: " to Vercel" },
                            ],
                        },
                    ],
                },
            ],
        },
    ],
};
